import { Component, EventEmitter, OnInit, Output, inject } from "@angular/core";
import { GlobalDatePickerService } from "src/app/@AppService/services/global-date-picker.service";
import { FormService } from "../../@AppService/services/form.service";

@Component({
  selector: "app-year-drop-down",
  templateUrl: "./year-drop-down.component.html",
  styleUrls: ["./year-drop-down.component.scss"]
})
export class YearDropDownComponent implements OnInit{
  datePickerService = inject(GlobalDatePickerService);
  formService = inject(FormService);
  yearToConfirmChange = this.datePickerService.getSelectedYear();
  openConfirmModel = false;
  harvestYearsList: string[] = [];
  @Output() yearSelected: EventEmitter<number> = new EventEmitter();

  ngOnInit() {
    this.listYears();
  }

  listYears() {
    const startYear = 2016;
    const endYear = new Date().getFullYear();
    for (let year = startYear; year <= endYear; year++) {
      this.harvestYearsList.push(this.datePickerService.constructHarvestYear(year));
    }
  }

  changeYear(year: number) {
    this.datePickerService.updateYear(year);
    this.openConfirmModel = false;
    this.formService.submitted = false;
  }

  openConfirmation(year: number) {
    this.yearToConfirmChange = year;
    this.openConfirmModel = true;
  }
}
